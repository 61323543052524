import React,{useState} from 'react';
import {Container, Row, Col, Carousel, Button, Tab, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';



import Banner1 from '../../Assets/Banner1.jpg';
import Banner2 from '../../Assets/Banner2.jpg';
import Banner3 from '../../Assets/Banner3.jpg';
import Banner4 from '../../Assets/Banner4.jpg';

import upcoming1 from '../../Assets/UpcomingA.jpg';
import upcoming2 from '../../Assets/UpcomingB.jpg';
import upcoming3 from '../../Assets/UpcomingC.jpg';
import upcoming4 from '../../Assets/UpcomingD.jpg';

import vibe from '../../Assets/vibe.png';
import YtVedioModal from './YtVedioModal';

import Creativity from '../../Assets/Creativity.svg';
import Success from '../../Assets/Success.svg';


// import MobileModel1 from '../../Assets//Banner1.jpg';
// import MobileModel2 from '../../Assets/MobileBanner-2.jpg';
// import MobileModel3 from '../../Assets/MobileBanner-3.jpg';


const Home = () => {

  const navigate = useNavigate();
      // --- States For Handling Carousels ----
        const [index, setIndex] = useState(0);
        const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
 };

 const [show, setShow] = useState(false);
 const [ytUrl, setYtUrl] = useState('');

 const [key, setKey] = useState("upcoming");

  return (
    <div className='home'>

      <YtVedioModal open={show} close={ () => setShow(false)} YtUrl={ytUrl}/>
      
        <Container fluid className='p-0' style={{overflow:'hidden'}}>
        <Row>
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
       <div className='banner' style={{backgroundImage:`url(${Banner1})`}}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={6} className='text-center'>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=33kqQutj7ac");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner1})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6}
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=33kqQutj7ac");}}/>
            </div>
          </Col>
        </Row>
       </div>

       
      </Carousel.Item>

      <Carousel.Item>
      <div className='banner' style={{backgroundImage:`url(${Banner2})`}}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={6} className='text-center'>
          <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6}
           onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=mjv4jctHz0M");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner2})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6}
             onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=mjv4jctHz0M");}}/>
            </div>
          </Col>
        </Row>
       </div>

      </Carousel.Item>
      <Carousel.Item>
      <div className='banner' style={{backgroundImage: `url(${Banner3})` }}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={8} className='text-center'>
          <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
          onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=zGj-ABFZlU4");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner3})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=zGj-ABFZlU4");}}/>
            </div>
          </Col>
        </Row>
       </div>
      </Carousel.Item>

      <Carousel.Item>
      <div className='banner' style={{backgroundImage: `url(${Banner4})` }}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={8} className='text-center'>
          <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
          onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=JLgGw1KNRbI");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner4})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=JLgGw1KNRbI");}}/>
            </div>
          </Col>
        </Row>
       </div>
      </Carousel.Item>
  
    </Carousel>
      </Row>
    </Container>


    <Container className='my-5 globalMusic'>
        <Row className=' d-flex justify-content-center'>
            <Col md={10}>
                <Row className='justify-content-center text-center text-md-start' data-aos="fade-up" data-aos-delay="200" data-aos-offset="50">
                  <h1 className='text-center mb-3'>Welcome to GME Pictures</h1>
                <Col md={3} className='me-0 me-md-4'>
                <h1>Where Creativity Comes to Life</h1>
                </Col>
                <Col md={8} className='para d-flex align-items-center'>
                    <p>As a dynamic division of Gurukrupa Media Entertainment Pvt Ltd, GME Pictures breathes life
              into the world of entertainment. We have established ourselves as trailblazers, excelling in the
              production of feature films, music, and captivating web series. Based in the vibrant city of
              Mumbai, Maharashtra (India), we proudly hold a niche in the prestigious Marathi Film Industry.

                    </p>
                </Col>
                </Row>
                <Button className='btn-login mx-auto mt-4' onClick={()=>navigate('/music')}><Icon.ArrowDownCircle className='me-2' size={16}/>Know More</Button>
            </Col>
        </Row>
    </Container>
    
    <div className='discover py-5'>
      <Container>
        <Row>
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row className='justify-content-center'>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                <Nav variant="pills" className="flex-row">
                <Nav.Item>
                    <Nav.Link eventKey="upcoming">
                      <Icon.ClockHistory className='me-2'/>Upcoming</Nav.Link>
                  </Nav.Item>  
                  <Nav.Item> 
                    <Nav.Link eventKey="released">
                      <Icon.Cassette className='me-2'/>Relased</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              </Row>

              <Row className='pe-0'>
                <Tab.Content>
                  <Tab.Pane eventKey='released'>
                    <Row className='text-center mt-4'>
                      <h2>No data found</h2>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey='upcoming'>
                  <h5 className='text-center mt-3'>Unlocking This Musical Marvels Soon !</h5>
                    <div className='upcoming mt-3 d-block d-md-none'>

                        <img src={upcoming1} alt='Special Datingla'/>
                     
                        <img src={upcoming2} className='img-fluid' alt='Kamyamcha Single'/>

                        <img src={upcoming3} className='img-fluid' alt='Kamyamcha Single'/>
                        
                        <img src={upcoming4}  alt='Pillu'/>
                    
                    </div>


                  <Row className='d-flex justify-content-center mt-4 d-none d-md-flex'>
                      <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" data-aos-delay="100">
                        <img src={upcoming1} className='img-fluid' alt='Special Datingla'/>
                      </Col>
                      <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" data-aos-delay="200">
                          <img src={upcoming2} className='img-fluid' alt='Kamyamcha Single'/>
                      </Col>
                      <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" data-aos-delay="300">
                          <img src={upcoming3} className='img-fluid' alt='Kamyamcha Single'/>
                      </Col>
                      <Col md={3} data-aos="fade-up" data-aos-delay="400">
                          <img src={upcoming4} className='img-fluid' alt='Pillu'/>
                      </Col>
                    </Row>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
           

            </Tab.Container>
            </Row>
        
      </Container>

    </div>


    <Container>
      <Row className='vibe justify-cotent-center align-items-center text-center mt-5 mb-5' data-aos="fade-up" data-aos-delay="200">
      <h2>Your Everyday Vibe, Our Everyday Music !</h2>
        <Col md={4}>
          <div >
              <img src={Creativity} alt="Creativity" width={40} className='mb-2'/>
              <h4 className='mb-0' style={{fontWeight:'600', color:'#e22128'}}> Unleash Your Creativity,</h4>
        
              <p>At GME Music, we're more than just a music distribution company; we're your ultimate allies in
                  the pursuit of making your <strong>music known to the world.</strong> Our passion lies in <strong>empowering artists
                  and musicians</strong> like you to showcase your unique talent and connect with your niche audience.
              </p>
            </div>
        </Col>

        <Col md={4}>
          <img src={vibe} className='img-fluid' alt="Everyday vibing Music"/>
        </Col>

        <Col md={4}>
      
              <img src={Success} alt="Creativity" width={40} className='mb-2'/>
              <h4 className='mb-0' style={{fontWeight:'600', color:'#e22128'}}> Partnering for Your Success,</h4>
            
              <p>Our team of music enthusiasts and industry experts is dedicated to <strong>supporting independent
                  artists</strong> on their path to success. We're here to <strong>amplify your voice,</strong> celebrate your artistry, and
                  provide you with the tools you need to thrive in the dynamic world of music.
              </p>
        </Col>
       
      </Row>

    </Container>

    </div>
  )
}

export default Home;