import React from "react";
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ScrollToTop from './Components/User/ScrollToTop';
import UserLayout from './Layout/UserLayout';
import Home from './Components/User/Home';
import About from "./Components/User/About";
import Contact from "./Components/User/Contact";
import Music from "./Components/User/Music";
import Digital from "./Components/User/Digital";

function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<UserLayout/>}>
        <Route path="/" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/digital" element={<Digital/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/music" element={<Music/>}/>
      </Route>

    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
