import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';

import MissionIcon from '../../Assets/MissionIcon.png';
import VisionIcon from '../../Assets/VisionIcon.png';

import Mission from '../../Assets/mission.jpg';

import soulMusic from '../../Assets/musicSoul.jpg'

const About = () => {
  return (
    <div className='about'>
        <div className='banner'>
        <Container className='h-100'>
            <Row className='h-100' data-aos="fade-up" data-aos-delay="200">
                <Col sm={12} md={{ span: 6, offset: 6 }} className='d-flex align-items-start align-items-md-center mt-3 mt-md-0'>
                    <div>
                        <h2 className='mb-2 mt-0 mt-md-5'>ABOUT US</h2>
                        <h4 className='mb-1'>#Who we are,</h4>
                        <p className='mb-3'>At GME Pictures, we stand at the forefront of entertainment, guided by a shared vision to curate
                            exceptional experiences for our audience. As a subsidiary company of Gurukrupa Media
                            Entertainment Pvt Ltd (GME), we are proud to be a dynamic production house, breathing life
                            into captivating feature films, heartwarming web series, and mesmerizing music.
                        </p>

                        <h4 className='mb-1'>#GME Music: A Harmonious Cadence</h4>
                        <p className='mb-3'>Within the GME umbrella, GME Music proudly stands as our music label, dedicated to exploring
                                the vast horizons of musical expression. From nurturing budding talents to collaborating with
                                renowned artists, GME Music is our avenue to embrace the universal language of melodies.
                        </p>

                        <div className='d-none d-md-block'>
                        <h4 className='mb-1'>#GME Digital: Unlocking Boundless Horizons</h4>
                        <p className='mb-0'>GME Digital, another venture under Gurukrupa Media Entertainment Pvt Ltd, embodies our
                            commitment to seamless content distribution. With cutting-edge technology as our backbone,
                            we unleash the power of digital platforms, ensuring that our creations reach every corner of the
                            world.
                        </p>
                        </div>
                       
                       
                    </div>
                </Col>
            </Row>
        </Container>
        </div>

        {/* <Container className='team mt-5'>
            <Row className='justify-content-center' data-aos="fade-up">
            <h1 className='text-center'>" Empowering Artists and Indie Musicians "</h1>
                <Col md={5}>
             
                <h2>GME Music: A Harmonious Cadence</h2>
                <p>
                    Within the GME umbrella, GME Music proudly stands as our music label, dedicated to exploring
                    the vast horizons of musical expression. From nurturing budding talents to collaborating with
                    renowned artists, GME Music is our avenue to embrace the universal language of melodies.
                </p>

                <h2>GME Digital: Unlocking Boundless Horizons</h2>
                <p>GME Digital, another venture under Gurukrupa Media Entertainment Pvt Ltd, embodies our
                    commitment to seamless content distribution. With cutting-edge technology as our backbone,
                    we unleash the power of digital platforms, ensuring that our creations reach every corner of the
                    world.
                </p>
                </Col>

                <Col md={5}>
                    <img src={soulMusic} className='img-fluid' alt="Passionate team"/>
                </Col>
             

            </Row>
        </Container> */}



        <Container className='my-5' id="mission">
            <Row>
            <h1 className='text-center mb-3' style={{fontWeight:'700'}}>" Empowering Artists and Indie Musicians "</h1>
                <Col md={6} data-aos="fade-up" data-aos-delay="100">
                    <img src={Mission} alt="Mission" className='img-fluid'/>
                </Col>
                <Col md={6} data-aos="fade-up" data-aos-delay="200">
                <div className='d-flex justify-content-center align-items-center mb-1'>
                        <img src={MissionIcon} alt="Mission" width={50} height={50} className='me-2'/>
                        <h1 className='mb-0' style={{fontWeight:'700'}}>Mission</h1>
                </div>
                      <p>We are on a mission to revolutionize the music landscape and empower artists and labels alike.
                        With a solid foundation of content and technical knowledge, we understand the challenges
                        artists face in publishing and distribution. Our unwavering commitment is to reprioritize and
                        reinvent the current musical scenario, ensuring that your music reaches new heights.
                      </p>

                      <p>As your trusted music partner, we are well-versed in the intricacies of the industry. Navigating
                    the complex music landscape can be daunting, but with us by your side, you can have full
                    confidence in the journey ahead.</p>
                    

                <div className='d-flex justify-content-center align-items-center mt-3 mb-1'>
                    <img src={VisionIcon} alt="Mission" width={50} height={50} className='me-2'/>
                    <h1 className='mb-0' style={{fontWeight:'700'}}>Vision</h1>
                </div>
                      <p> Our expertise lies in helping your music/song find its audience
                        and creating a seamless distribution strategy that maximizes its impact.
                        We extend a warm embrace to indie musicians, celebrating the vibrant creativity and artistry you
                        bring to the world. 
                        </p>
                        
                </Col>
            </Row> 
        </Container>
    </div>
  )
}

export default About;