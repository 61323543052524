import React from 'react';
import ReactPlayer from 'react-player';
import * as Icon from 'react-bootstrap-icons';
import { Button, Modal } from 'react-bootstrap';

const YtVedioModal = ({open, close, YtUrl}) => {

  return (
    <Modal dialogClassName="modal" show={open} onHide={close} centered backdrop="static" keyboard={false} size="lg" >
        {/* <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <h5 className="mb-0">Login</h5>
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="px-3 py-3">
            <div className='d-none d-md-block'>
                <ReactPlayer url={YtUrl} controls width="100%" height="60vh"/>
            </div>
            <div className='d-block d-md-none'>
                <ReactPlayer url={YtUrl} controls width="100%" height="100%"/>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={close}>
            <Icon.XLg className="me-2" /> Cancel
          </Button>
            <Button href={YtUrl} target='_blank' className="btn-login">
              <Icon.Youtube className="me-2"/>
              Watch this on YT
            </Button>
       
        </Modal.Footer>
      </Modal>
  )
}

export default YtVedioModal;