import React from 'react'
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import Distribution from '../../Assets/distirbute.jpg';
import * as Icon from 'react-bootstrap-icons';

const Digital = () => {
  return (
    <div className='digital'>
        <div className='banner'>
        <Container className='h-100'>
            <Row className='h-100 justify-content-center align-items-center text-center'>
                <h1 data-aos="fade-up">GME Music Distribution, <br></br>Unlocking Your Music's Global Potential!</h1>
            </Row>
        </Container>
        </div>

        <Container className='distribute my-5'>
            <Row className='justify-content-center' data-aos="fade-up">
                <h1 className='text-center mb-4' style={{fontWeight:'700'}}>DISTRIBUTE YOUR MUSIC TODAY</h1>
                <Col md={5} className='d-flex align-items-center'>
                    <div>
                    <p>At GME Music Distribution, we take pride in being your all-inclusive 360-degree music
                        distributor, dedicated to amplifying your music's reach across the digital landscape. Our
                        comprehensive distribution services encompass a vast network of platforms, including iTunes,
                        Deezer, Spotify, Facebook, Google Play, Youtube, Beatport, Apple Music, Amazon, and over
                        250 other digital partner stores.
                    </p>

                    <p>If you're an indie artist seeking a platform to showcase your talent, look no
                        further. GME Pictures welcomes you with open arms, providing unwavering support and
                        valuable resources to help you shine and make your mark in the music realm.</p>
                    </div>
                </Col>

                <Col md={5}>
                    <img src={Distribution} alt="Distribution" className='img-fluid'/>
                </Col>
            </Row>
        </Container>

        <Container className='features mb-5'>
            <Row data-aos="fade-up">
                <h1 className='text-center mb-4' style={{fontWeight:'700'}}>DISTRIBUTION FEATURES</h1>
                <Col md={3} className='mb-4 mb-md-0'>
                    <Card className='h-100'>
                        <Icon.CalendarHeart size={40} color='#e22128' className='mx-auto mb-3'/>
                        <h2>30 Days Counting</h2>
                    </Card>
                </Col>

                <Col md={3} className='mb-4 mb-md-0'>
                    <Card className='h-100'>
                        <Icon.MusicNoteBeamed size={40} color='#e22128' className='mx-auto mb-3'/>
                        <h2>Playlist Pitching</h2>
                    </Card>
                </Col>

                <Col md={3} className='mb-4 mb-md-0'>
                    <Card className='h-100'>
                        <Icon.ShieldCheck size={40} color='#e22128' className='mx-auto mb-3'/>
                        <h2>Certified Partnership</h2>
                    </Card>
                </Col>

                <Col md={3}>
                    <Card className='h-100'>
                        <Icon.Globe size={40} color='#e22128' className='mx-auto mb-3'/>
                        <h2>Truely global outlook</h2>
                    </Card>
                </Col>
            </Row>
        </Container>

        <Container className='digiContact mb-5'>
            <Row className='d-flex text-center justify-content-center'>
                <h1 style={{fontWeight:'700'}}>WANT TO GET IN TOUCH ?</h1>
                <h6>Reach out to us, here.</h6>
                
                    <Col md={4}>
                    <FloatingLabel label="Name*" className='mb-3'>
                        <Form.Control placeholder='Name'/>
                    </FloatingLabel>
                    
                
                   
                    <FloatingLabel label="Contact Number*" className='mb-3'>
                        <Form.Control placeholder='Contact Number'/>
                    </FloatingLabel>
                   
              
               
                    <FloatingLabel label="Email ID*" className='mb-3'>
                        <Form.Control placeholder='Email ID*'/>
                    </FloatingLabel>
            
                    <FloatingLabel label="Facebook Profile Link" className='mb-3'>
                        <Form.Control placeholder='Facebook Profile Link'/>
                    </FloatingLabel>
                  
                
                    <FloatingLabel label="Youtube Profile Link" className='mb-3'>
                        <Form.Control placeholder='Youtube Profile Link'/>
                    </FloatingLabel>
                    </Col>   
            </Row>
            <Button className='btn-login mx-auto'><Icon.Send className='me-2'/>Submit</Button>
        </Container>
       
    </div>
  )
}

export default Digital;