import React from 'react';
import UserNav from '../Components/User/UserNav';
import {Outlet} from 'react-router-dom'
import Footer from '../Components/User/Footer';


const UserLayout = () => {
  return (
    <>
    <UserNav/>
    <Outlet/>
    <Footer/>
    </>
  )
}

export default UserLayout;