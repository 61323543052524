import React, {useState,useEffect} from 'react';
import { Link } from "react-router-dom";
import logo from '../../Assets/logo.png';
import * as Icon from 'react-bootstrap-icons';
import { Button, Modal } from 'react-bootstrap';


const UserNav = () => {

  function setnav(){
    const mobilenav = document.querySelector(".mobile-nav");
    const toggle = document.querySelector(".mobile-nav-toggle");
  
    const visibility = mobilenav.getAttribute('data-visible' );
    if(visibility ==="false"){
        mobilenav.setAttribute("data-visible" , true);
        toggle.setAttribute("aria-expanded", true);
        document.querySelector("body").style.overflow='hidden';
    }
    else if(visibility === "true"){
        mobilenav.setAttribute("data-visible" , false);
        toggle.setAttribute("aria-expanded", false);
        document.querySelector("body").style.overflow='auto';
        
    }
  }


  
  useEffect(()=>{
    changeNavbarColor();
  },[])
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () =>{
     if(window.scrollY >= 100){
       setColorchange(true);
     }
     else{
       setColorchange(false);
     }
  }
  window.addEventListener('scroll', changeNavbarColor);


  const [showLogin, setShowLogin] = useState(false);
  const closeModal=()=>{
    setShowLogin(false);
  }

  return (
    <>

    <nav className={colorChange ? 'navbarBlack' : 'navbarTransperent'}>
    <Link to="/"><img src={logo} alt="THE ORCHARD" width={60} height={35} data-aos="fade-down" data-aos-delay="200"/></Link>
    <div className="mobile-nav-toggle" aria-controls='selectNav' aria-expanded="false"  onClick={setnav}>
        <div className='menu-btn__burger'></div>
    </div>
 
    <ul className="lg-nav" data-aos="fade-down" data-aos-delay="200">
    <Link to="/"><li>HOME</li></Link>
    <Link to="/about"><li>ABOUT</li></Link>
    <Link to="/music"><li>MUSIC</li></Link>
    <Link to='/digital'> <li>DIGITAL</li></Link>
    <Link><li>NEWS</li></Link>
    <Link to="/contact"><li>CONTACT</li></Link>
    <Button className='btn-login ms-2 px-3 py-1' onClick={()=>setShowLogin(true)}>LOGIN</Button>
    </ul>

    
    <ul id="selectNav" className="mobile-nav" data-visible="false">
    <Link to="/" style={{textDecoration:'none'}}><li className="active" onClick={setnav}><Icon.HouseUp className='me-3' size={30}/>HOME</li></Link>
    <Link to="/about" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.BagHeart className='me-3' size={30}/>ABOUT</li></Link>
    <Link to="/music" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.MusicNote className='me-3' size={30}/>MUSIC</li></Link>
    <Link to="/digital" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.Camera className='me-3' size={30}/>DIGITAL</li></Link>
    <Link style={{textDecoration:'none'}}><li onClick={setnav}><Icon.Newspaper className='me-3' size={30}/>NEWS</li></Link>
    <Link to="/contact" style={{textDecoration:'none'}}><li onClick={setnav}><Icon.TelephoneForward className='me-3' size={30}/>CONTACT</li></Link>
    <Button className='btn-login ms-2 px-3 py-1' onClick={()=>{setnav(); setShowLogin(true)}}>LOGIN</Button>
    </ul>
</nav>


 {/* ----------- Add Modal ---------------- */}
 <Modal dialogClassName="modal" show={showLogin} onHide={closeModal} centered backdrop="static" keyboard={false} size="lg" >
        <Modal.Header closeButton>
          <Modal.Title className="d-flex align-items-center">
            <h5 className="mb-0">Login</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 py-4">
            <h5>This is feature will be avail soon, we appreciate your patience.</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-cancel" onClick={closeModal}>
            <Icon.XLg className="me-2" /> Cancel
          </Button>
            <Button className="btn-login" onClick={closeModal} disabled>
              <Icon.BoxArrowInRight className="me-2" />
              Login
            </Button>
       
        </Modal.Footer>
      </Modal>
</>
  )
}

export default UserNav;