import React, { useRef } from 'react';
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import * as Icon from 'react-bootstrap-icons';
import MsgBox from '../../Assets/MsgBox.png';


const Contact = () => {

    const notify = () => toast.success("Submitted Successfully!");
    const notsend = () => toast.error("Form not sent, Please try again");
  
    const form = useRef();
  
   const sendEmail = (e) => {
      e.preventDefault();
     console.log("Sending Form Data");
    //   emailjs.sendForm('service_zfenldc', 'template_a1hvudo', form.current, 'OWzMq6nAsySEuGAPN')
    //     .then((result) => {
    //         console.log(result.text);
    //         notify()
          
    //     }, (error) => {
    //         console.log(error.text);
    //         notsend();
        
    //     });
  
       document.getElementById('new').reset();
    };
  
  return (
    <div className='contact'>
        <ToastContainer/>
        <div className='banner'>
        <Container className='h-100'>
            <Row className='h-100 align-items-center'>
                <Col md={6}>
                    <Card>
                       
                        <div className='text-center d-flex justify-content-center align-items-center mb-1'>
                            <img src={MsgBox} width={50} height={50} className='me-2'/>
                            <h3 className='text-center mb-0'>Enquire Now</h3>
                          
                        </div>
                        <p className='mb-1'>Drop us a line, share your musical brilliance, and let's embark on an enchanting journey of
                                collaboration. Whether you're a budding talent or an established artist, we embrace diversity
                                and celebrate creativity in all its forms.
                            </p>

                    <Row className='mails d-flex justify-content-between my-3'>
                        <Col md={4} className='d-flex align-items-center justify-content-center'>
                            <a href="mailto:music@gmepictures.com">music@gmepictures.com</a>
                        </Col>
                        <Col md={4} className='d-flex align-items-center justify-content-center'>
                            <a href="mailto:legal@gmepictures.com">legal@gmepictures.com</a>
                        </Col>
                        <Col md={4} className='d-flex align-items-center justify-content-center'>
                            <a href="mailto:music@gmepictures.com">music@gmepictures.com</a>
                        </Col>  
                    </Row>
                            
                        
                 <Form ref={form} onSubmit={sendEmail} className="contact-form" id="new">
                    <Row>
                        <Col md={6}>
                        <FloatingLabel label="Name" className="mb-3 mb-md-4" data-aos="fade-up" data-aos-delay="100">
                        <Form.Control type="name" name="name" placeholder="name" required />
                    </FloatingLabel>
                        </Col>

                        <Col md={6}>
                        <FloatingLabel label="Mobile Number" className="mb-3 mb-md-4" data-aos="fade-up" data-aos-delay="200">
                    <Form.Control type="tel" name="mobile" placeholder="Mobile Number" required/>
                </FloatingLabel>
                        </Col>
                    </Row>
                  

               

                <FloatingLabel label="Email" className="mb-3 mb-md-4" data-aos="fade-up" data-aos-delay="300">
                    <Form.Control type="email" name="email" placeholder="name@example.com" required/>
                </FloatingLabel>

                <FloatingLabel label="Message" className="mb-3 mb-md-4" data-aos="fade-up" data-aos-delay="300">
                    <Form.Control as="textarea"  name="email" placeholder="name@example.com" required/>
                </FloatingLabel>



             
                    <Button type="submit" className="btn-login mx-auto" data-aos="fade-up" data-aos-delay="400">
                        <Icon.Send className='me-1'/>Submit</Button>
                 
            </Form>
            </Card>
                </Col>
            </Row>
           
            
        </Container>

   

        </div>

             {/* ---- Google map location ---- */}
             <Container className='location my-5'>
          <Row>
            <div className='d-flex justify-content-center align-items-center mb-2'>
                <Icon.GeoAltFill className='me-2' size={25}/>
                <h2 className='mb-0' style={{fontWeight:'700'}}>Our Location</h2>
            </div>
            
            <Col md={12}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7567.182180528529!2d73.85862514073972!3d18.502172380351155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c014271918c5%3A0xfa3ce63f0c494d3f!2sSwargate%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1687635059256!5m2!1sen!2sin"
            style={{border:'0', borderRadius:'15px', width:'100%', height:'50vh'}} allowfullscreen="" loading="lazy" 
             referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Col>
          </Row>
        </Container>
        
    </div>
  )
}

export default Contact;