import React,{useState} from 'react';
import {Container, Row, Col, Carousel, Tab, Nav } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';


import Banner1 from '../../Assets/Banner1.jpg';
import Banner2 from '../../Assets/Banner2.jpg';
import Banner3 from '../../Assets/Banner3.jpg';
import Banner4 from '../../Assets/Banner4.jpg';

import upcoming1 from '../../Assets/UpcomingA.jpg';
import upcoming2 from '../../Assets/UpcomingB.jpg';
import upcoming3 from '../../Assets/UpcomingC.jpg';
import upcoming4 from '../../Assets/UpcomingD.jpg';

import YtVedioModal from './YtVedioModal';

import music from '../../Assets/music.png';
import Sanju from '../../Assets/Sanju.jpg';

// import MobileModel1 from '../../Assets//Banner1.jpg';
// import MobileModel2 from '../../Assets/MobileBanner-2.jpg';
// import MobileModel3 from '../../Assets/MobileBanner-3.jpg';


const Music = () => {

      // --- States For Handling Carousels ----
        const [index, setIndex] = useState(0);
        const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
 };

 const [show, setShow] = useState(false);
 const [ytUrl, setYtUrl] = useState('');

 const [key, setKey] = useState("upcoming");

  return (
    <div className='home'>

      <YtVedioModal open={show} close={ () => setShow(false)} YtUrl={ytUrl}/>
      
        <Container fluid className='p-0' style={{overflow:'hidden'}}>
        <Row>
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
       <div className='banner' style={{backgroundImage:`url(${Banner1})`}}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={6} className='text-center'>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=33kqQutj7ac");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner1})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6}
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=33kqQutj7ac");}}/>
            </div>
          </Col>
        </Row>
       </div>

       
      </Carousel.Item>

      <Carousel.Item>
      <div className='banner' style={{backgroundImage:`url(${Banner2})`}}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={6} className='text-center'>
          <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6}
           onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=mjv4jctHz0M");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner2})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6}
             onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=mjv4jctHz0M");}}/>
            </div>
          </Col>
        </Row>
       </div>

      </Carousel.Item>
      <Carousel.Item>
      <div className='banner' style={{backgroundImage: `url(${Banner3})` }}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={8} className='text-center'>
          <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
          onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=zGj-ABFZlU4");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner3})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=zGj-ABFZlU4");}}/>
            </div>
          </Col>
        </Row>
       </div>
      </Carousel.Item>

      <Carousel.Item>
      <div className='banner' style={{backgroundImage: `url(${Banner4})` }}>
        <Row className='h-100 w-100 d-flex justify-content-center align-items-center'>
          <Col md={8} className='text-center'>
          <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
          onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=JLgGw1KNRbI");}}/>
          </Col>
        </Row>
       </div>

       <div className='mobile-banner' style={{backgroundImage:`url(${Banner4})`}}>
        <Row className='h-100 w-100'>
          <Col className='MobileModel text-center'>
            <div>
            <Icon.PlayCircle size={60} color='#ffffff' opacity={0.6} 
            onClick={()=>{ setShow(true); setYtUrl("https://www.youtube.com/watch?v=JLgGw1KNRbI");}}/>
            </div>
          </Col>
        </Row>
       </div>
      </Carousel.Item>
  
    </Carousel>
      </Row>
    </Container>

<Container className='my-5 music'>
  <Row data-aos="fade-up">
    <Col md={7} className='d-flex align-items-center'>
    <div>
    <h1><span>MUSIC</span> INSPIRES,</h1>
    <h1><span>MUSIC</span> RELAX THE SOUL,</h1>
    <h1><span>MUSIC</span> NURTURES THE MINDS, </h1>
    <h1><span>MUSIC</span> REALESES THE STRESS</h1>
    </div>
    </Col>
    <Col md={5}>
      <img src={music} alt='music does a lot' className='img-fluid'/>
      </Col>
  </Row>
</Container>

    
<div className='discover py-5'>
      <Container>
        <Row>
      <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
              <Row className='justify-content-center'>
                <Col xs={12} md={4} className='d-flex justify-content-center'>
                <Nav variant="pills" className="flex-row">
                <Nav.Item>
                    <Nav.Link eventKey="upcoming">
                      <Icon.ClockHistory className='me-2'/>Upcoming</Nav.Link>
                  </Nav.Item>  
                  {/* <Nav.Item> 
                    <Nav.Link eventKey="released">
                      <Icon.Cassette className='me-2'/>Relased</Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
              </Row>

              <Row className='pe-0'>
                <Tab.Content>
                  <Tab.Pane eventKey='released'>
                    <Row className='text-center mt-4'>
                      <h2>No data found</h2>
                    </Row>
                  </Tab.Pane>

                  <Tab.Pane eventKey='upcoming'>
                    <h5 className='text-center mt-2'>Stay Tuned for Rhythmic Revelations!</h5>
                      <div className='upcoming mt-3 d-block d-md-none'>

                        <img src={upcoming1} alt='Special Datingla'/>
                     
                        <img src={upcoming2} className='img-fluid' alt='Kamyamcha Single'/>

                        <img src={upcoming3} className='img-fluid' alt='Kamyamcha Single'/>
                        
                        <img src={upcoming4}  alt='Pillu'/>
                    
                    </div>


                  <Row className='d-flex justify-content-center mt-4 d-none d-md-flex'>
                      <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" data-aos-delay="100">
                        <img src={upcoming1} className='img-fluid' alt='Special Datingla'/>
                      </Col>
                      <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" data-aos-delay="200">
                          <img src={upcoming2} className='img-fluid' alt='Kamyamcha Single'/>
                      </Col>
                      <Col md={3} className='mb-3 mb-md-0' data-aos="fade-up" data-aos-delay="300">
                          <img src={upcoming3} className='img-fluid' alt='Kamyamcha Single'/>
                      </Col>
                      <Col md={3} data-aos="fade-up" data-aos-delay="400">
                          <img src={upcoming4} className='img-fluid' alt='Pillu'/>
                      </Col>
                    </Row>
                    <p className='text-center mt-3 mb-0'>Thank you for being part of our musical family. Until the next beat drops, let the melodies
continue to weave their magic in your heart and soul.
</p>
                  </Tab.Pane>
                </Tab.Content>
              </Row>
           

            </Tab.Container>
            </Row>
        
      </Container>

    </div>

    <Container className='my-5 music'>
        <Row className='justify-content-center'>
          <Col md={5}>
            <h1>WE ALSO UPGRADES WITH MODERN</h1>
            <h1><span>MUSIC</span></h1>
            <h1>AND</h1>
            <h1><span>TRENDS</span></h1>
            <p>We undertand the nerves of modern music, swag and culture. So, We promise you to deliever best modern & trendy music soon, <span>stay connected !</span></p>
            <div className='icons mb-4 mb-md-0'>
            <a href='https://www.instagram.com/gme.ltd/' target='_blank' rel="noreferrer"> <Icon.Instagram  size={22}/> </a>
                    <a href='https://www.linkedin.com/company/gmeofficial/' target='_blank' rel="noreferrer"> <Icon.Linkedin size={22}/> </a>
                    <a href='https://www.facebook.com/gmemusicindia' target='_blank' rel="noreferrer"> <Icon.Facebook size={22}/> </a>
                    <a href='https://www.youtube.com/@gmemarathi' target='_blank' rel="noreferrer"> <Icon.Youtube size={22}/></a>
                    <a href='https://twitter.com/gme_india' target='_blank' rel="noreferrer"> <Icon.Twitter size={22}/></a>
            </div>
          </Col>
          
          <Col md={5}>
            <img src={Sanju} alt="Modern Music & Art"  className='img-fluid'/>
          </Col>

        </Row>
      </Container>



    </div>
  )
}

export default Music;