import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import logo from '../../Assets/logo.png'
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate =useNavigate();
  return (
    <footer>
        <Container className='pt-3 pb-3 px-5'>
            <Row className='d-flex justify-content-between mt-3'>
                <Col md={2} className='d-flex justify-content-center align-items-center'>
                   <img src={logo} alt="logo" width={90} className='me-3 me-md-0'/>
                </Col>
                <Col md={4} className='menus my-4 my-md-0'>
                    <h6 data-aos="fade-up" data-aos-delay="100" onClick={()=>navigate('/')}>HOME</h6>
                    <h6 data-aos="fade-up" data-aos-delay="200" onClick={()=>navigate('/about')}>ABOUT</h6>
                    <h6 data-aos="fade-up" data-aos-delay="300" onClick={()=>navigate('/contact')}>CONTACT</h6>
                    <h6 data-aos="fade-up" data-aos-delay="400">LOGIN</h6>
                </Col>
                <Col md={2} className='icons'>
                    <a href='https://www.instagram.com/gme.ltd/' target='_blank' rel="noreferrer"> <Icon.Instagram  size={22}/> </a>
                    <a href='https://www.linkedin.com/company/gmeofficial/' target='_blank' rel="noreferrer"> <Icon.Linkedin size={22}/> </a>
                    <a href='https://www.facebook.com/gmemusicindia' target='_blank' rel="noreferrer"> <Icon.Facebook size={22}/> </a>
                    <a href='https://www.youtube.com/@gmemarathi' target='_blank' rel="noreferrer"> <Icon.Youtube size={22}/></a>
                    <a href='https://twitter.com/gme_india' target='_blank' rel="noreferrer"> <Icon.Twitter size={22}/></a>
                </Col>
                
                <p className='mt-4'>Copyright © 2023 Gurukrupa Media Entertainment Pvt Ltd. All rights reserved.</p>
            </Row>
        </Container>

    </footer>
  )
}

export default Footer;